body {
  margin: 0;
  font-family: "Anuphan", sans-serif;
  font-size: 14px;
  color: #EFEFEF;
  background-color: #1c1c1c;
}

@media (min-width: 1010px) {
  .root {
    background-image: url('./assets/hero-desktop.png');
  }

  .app {
    width: 1080px;
    max-width: 90vw;
  }

  .header-inner {
    width: 65vw;
    max-width: 1080px;
  }

  .methods-panel,
  .main-container,
  .info-panel {
    padding: 16px;
  }

  .profile-container {
    width: 1080px;
    padding: 0px 32px 0px 32px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-panel {
    width: 1048px;
    max-width: 85vw;
    box-sizing: border-box;
    margin-bottom: 24px;
  }

  .methods-btns {
    border-right: 1px solid #474239;
    flex-direction: row;
    min-width: 400px;
  }

  .score-panel {
    margin: 12px;
    min-width: 300px;
    background-image: url('./assets/clearoff.jpg');
  }

  .form-panel {
    min-width: 510px;
  }

  .form-section-subs {
    min-width: 260px;
    margin: 0px 16px 16px 0px;
  }

  .relic-form {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .charset-container {
    width: 440px;
  }

  .scored-inventory {
    margin: 0px 8px 16px 0px;
  }

  .searched-user--inner {
    min-width: 400px;
    max-width: 400px;
  }

  .char-guide-panel {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media (max-width: 1010px) {
  .root {
    background-image: url('./assets/hero-mobile.png');
  }

  .header-items {
    display: none;
  }

  .app,
  .header-inner {
    width: 95vw;
  }

  .main-container {
    padding: 0;
  }

  .profile-container {
    width: 95vw;
    padding: 8px;
  }

  .profile-panel {
    margin-bottom: 8px;
  }

  .methods-btns {
    border-bottom: 1px solid #474239;
    border-width: 75%;
    min-width: none;
  }

  .methods-panel {
    flex-direction: column;
  }

  .form-panel {
    width: 100%;
    min-width: none;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-section-subs {
    min-width: 10px;
    align-items: center;
    margin: 0px 0px 16px 0px;
  }

  .relic-form {
    align-items: center;
  }

  .charset-container {
    max-width: 288px;
    justify-content: center;
  }

  .charset-container::-webkit-scrollbar {
    width: 0px;
  }

  .charset-container::-webkit-scrollbar-thumb {
    width: 0px;
  }

  .basis-form {
    align-items: center;
  }

  .score-panel {
    margin: 12px;
    min-width: 250px;
    background-image: url('./assets/clearoff.jpg');
  }

  .header-search {
    min-width: none;
  }

  .scale-inventory-container {
    width: 100%;
  }

  .inv-viewing {
    scale: 95%
  }

  .searched-user--inner {
    min-width: 250px;
    max-width: 250px;
  }

  .char-guide-panel {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

::selection {
  background-color: yellow;
  color: black;
}

hr {
  -webkit-mask: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  mask: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  margin: 16px 0px 16px 0px
}

a {
  color: rgb(250, 250, 123)
}

.stat-dropdown {
  max-width: 170px;
  font-family: "Anuphan", sans-serif;
  font-weight: 600;
  font-size: 14px;
  background-color: #00000034;
  padding: 8px;
  margin: 4px 0px 4px 0px;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  height: 32px;
  cursor: pointer;
}

.substat-input {
  background: none;
  border: none;
  color: white;
  font-family: "Anuphan", sans-serif;
  font-weight: 100;
  font-size: 14px;
  width: 48px;
  background-color: #00000034;
  padding: 5px 4px 5px 4px;
  border-bottom: 1px solid white;
  margin: 4px 0px 4px 0px;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid #00a2ed44;
}

.substat-input:focus {
  outline: 3px solid #00a2ed44;
}

.substat-dropdown {
  background: none;
  border: none;
  color: white;
  font-family: "Anuphan", sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  background-color: #00000034;
  border-bottom: 1px solid white;
  padding: 4px;
  margin: 4px 10px 4px 0px;
}

option {
  color: white;
  background-color: #1c1c1c;
  padding: 8px 12px;
  font-size: 14px;
}

.default-btn {
  font-family: "Anuphan", sans-serif;
  font-weight: 600;
  color: black;
  border: none;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: 1px solid #D4D5D9;
  outline-offset: -3px;
  height: 32px;
}

.default-btn:hover,
.default-btn:focus-visible {
  background-color: #333333;
  color: #DBC291;
  border: 2px solid #FFC870;
  cursor: pointer;
}

textarea {
  margin: 8px 0px 8px 0px;
  background-color: #00000034;
  color: rgba(255, 255, 255, 0.701);
  border: none;
  border-bottom: 1px solid white;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
}

.header,
.mobile-menu,
.header-inner {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  height: 60px;
  background-color: #0F0F0F;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  top: 0;
  z-index: 99999;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
}

.header-search {
  height: 60%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin: 8px;
}

.header-search>button {
  height: 100%;
  width: auto;
  cursor: pointer;
  padding: 8px 8px 8px 16px;
  border: none;
  background: #FFFFFF10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-search>button>img {
  height: 75%;
  width: auto;
  opacity: 25%;
}

.header-search>button:focus {
  outline: none;
}

.header-search>input {
  background: none;
  border: none;
  color: white;
  font-family: "Anuphan", sans-serif;
  font-weight: 400;
  font-size: 14px;
  background-color: #FFFFFF10;
  padding-left: 8px;
  outline: none;
  min-width: 175px;
}

.header-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 100%
}

.header-item {
  margin: 8;
  color: white;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  padding: 0px 16px 0px 16px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  font-family: "Anuphan", sans-serif;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom: 2px solid white;
}

.header-items>img {
  margin: 0px 16px 0px 32px;
}

.header-user>button {
  margin: 8px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  font-family: "Anuphan", sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid white;
  transition: background-color 0.3s ease;
  transition: border 0.3s ease;
  transition: color 0.3s ease;
}

.header-user>button:hover {
  border: 1px solid #e5eb34;
  background-color: #e5eb34;
  color: black
}

.footer {
  background: none;
  width: 1080px;
  max-width: 90vw;
  padding: 32px 0px 32px 0px;
  color: rgba(255, 255, 255, 0.4);
}

.footer--link {
  color: rgba(255, 255, 255, 0.4);
  margin: 0px;
  text-decoration: none;
}

.footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.mobile-menu {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  bottom: 0;
  height: 60px;
  padding: 0px 16px 0px 16px;
}

.mobile-menu--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 6px;
  align-items: center;
  height: 100%;
  background: none;
  color: white;
  border: none;
  font-family: "Anuphan", sans-serif;
  font-weight: 400;
  font-size: 10px;
  cursor: pointer;
  text-decoration: none;
}

.mobile-menu--item>img {
  max-height: 24px;
  margin-bottom: 4px;
}

.home-hero-demo {
  transform: translateX(-300px) translateY(-32px);
  transition: transform 0.3s ease-in-out;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.8));
}

.home-hero-demo.initial {
  transform: rotateX(11deg) rotateY(-11deg) translateX(-300px) translateY(-32px);
}

.home-hero-demo:hover {
  cursor: pointer;
  transform: rotateX(11deg) rotateY(-11deg) translateX(-300px) translateY(-32px) translateZ(50px);
}

.home-feature {
  flex: 1;
  min-width: 250px;
  border: 1px solid #474239;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin: 8px
}

.home-feature--bg {
  width: 100%;
  -webkit-mask: linear-gradient(0deg, rgba(60, 48, 31, 0) 0%, rgba(0, 0, 0, 1) 50%);
  mask: linear-gradient(0deg, rgba(60, 48, 31, 0) 0%, rgba(0, 0, 0, 1) 50%);
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 0;
  opacity: 25%
}

.home-feature--link {
  margin: 8;
  color: white;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 99px;
  border: 1px solid #474239;
  transition: background-color, border 0.3s ease;
  box-sizing: border-box;
  font-family: "Anuphan", sans-serif;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-feature--link:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid white;
}

.app {
  margin-top: 32px;
  box-sizing: border-box;
}

.methods-panel,
.main-container,
.info-panel {
  max-width: 100%;
  margin-bottom: 24px;
  border: 1px solid #474239;
  overflow-wrap: 'text';
  background-color: #282828;
  overflow: hidden;
  border-radius: 8px;
}

.methods-panel {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.methods-btns {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  padding: 16px;
}

.methods-desc {
  flex: 1;
  padding: 16px
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  overflow-y: auto;
}

.round-input {
  font-family: "Anuphan", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: white;
  outline: none;
  padding-left: 8px;
}

.form-panel {
  padding: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.score-panel,
.scored-inventory {
  min-height: 607px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid white;
  background-color: #00000034;
  background-size: cover;
  background-position: 50%;
  z-index: 1;
}

.score-display {
  z-index: 100;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  padding: 14px;
  font-family: "Anuphan", sans-serif;
  font-size: 14px;
  min-width: 200px;
  max-height: 80%;
  overflow-y: auto;
  backdrop-filter: blur(4px);
}

.score-display::-webkit-scrollbar {
  width: 10px;
}

.score-display::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.relic-img {
  position: relative;
  background: linear-gradient(to bottom, #74443F, #CBA368);
  border-radius: 12px;
  width: 64px;
  height: 64px;
  margin: 16px 16px 0px 16px;
  align-self: center;
}

.relic-img>img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%
}

.relic-img>p {
  position: absolute;
  margin: 0;
  top: 2px;
  left: 2px;
  z-index: 2;
}

.rvdisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px 2px 8px;
  height: 26px;
}

.rvdisplay>p {
  margin: 0;
  margin-right: 16px;
  font-size: 14px;
}

.sub-rolls-icons {
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  letter-spacing: 2px
}

.unit-rsdisplay {
  width: 58px;
  height: 58px;
  border-radius: 4px;
  position: relative;
}

.unit-rsdisplay>p {
  position: absolute;
  bottom: 0;
  right: 2px;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  text-shadow: 1px -2px 2px black;
}

.unit-rsdisplay>img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  font-weight: 600;
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0));
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0));
}

.unit-rsdisplay--empty {
  width: 58px;
  height: 58px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.basis-form {
  display: flex;
  flex-direction: column;
}

.charset-container {
  height: 146px;
  background-color: #00000034;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border-bottom: 1px solid white;
}

.charset-char {
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  margin: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.charset-char:hover {
  box-shadow: 0px 0px 10px rgb(125, 125, 125);
}

.charset-char>img {
  width: 125%;
  height: auto;
  transition: transform 0.1s ease-in-out;
}

.charset-char:hover>img {
  transform: scale(1.1);
}

.scale-inventory-container {
  align-self: center;
}

.scored-inventory {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 3px;
  align-content: baseline;
  min-height: 350px;
  max-height: 350px;
  min-width: 100px;
  padding: 3px;
  overflow-y: auto;
  justify-content: flex-start;
  background-color: #0F0F0F;
  box-sizing: border-box;
}

.inv-viewing {
  box-sizing: border-box;
  padding: 12px;
  min-height: 400px;
  border: 3px solid white;
  background: radial-gradient(circle, rgba(18, 18, 18, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
  margin: 0px 0px 0px 8px;
  min-width: 300px;
  max-width: 300px;
}

.inventory-info {
  display: flex;
  align-items: center;
  background-color: #00000034;
  padding: 8px;
  border-radius: 0px 64px 0px 0px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(168deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0.34) 46%);
  border: 3px solid #ffffff2f;
}

.inventory-info--banner,
.inventory-info--bannerMobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.inventory-info--bannerMobile {
  -webkit-mask-image: linear-gradient(160deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.75));
  mask-image: linear-gradient(160deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.75));
}

.inv-relic {
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: #1a1a1a;
  transition: background-color 0.1s ease-in-out;
}

.inv-relic>img {
  width: 70%;
  height: 70%;
  transition: filter 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

.inv-relic:hover {
  background-color: #262626;
}

.inv-relic:hover>img {
  filter: drop-shadow(-2px 2px 4px black);
  transform: scale(1.1)
}

.inv-relic>p {
  margin: 0px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  text-shadow: -2px -2px 2px black;
  color: white;
  font-size: 12px;
}

.inv-viewing {
  display: flex;
  flex-direction: column;
}

.searched-user {
  height: 82px;
  background-color: #F7F7F7;
  color: #171717;
  margin: 8px;
  padding: 0px 0px 0px 18px;
  border-radius: 0px 12px 0px 0px;
  overflow: hidden;
}

.searched-user--inner {
  height: 100%;
  background-color: #D6D4D7;
  width: 100%;
  border-radius: 99px 0px 0px 99px;
  display: flex;
  align-items: center;
}

.searched-user--inner>img {
  height: 85%;
  aspect-ratio: 1/1;
  border-radius: 99px;
  margin: 0px 8px 0px 8px;
}

.animated-element {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.animated-element.animate {
  opacity: 1;
  transform: translateY(0);
}

.home-hero {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  background-image: url('./assets/clearoff.jpg');
  background-size: cover;
  background-attachment: fixed;
}


.profile-container {
  background-color: #161616;
  box-sizing: border-box;
}

.profile-panel {
  max-width: 100%;
  padding: 16px;
  border: 1px solid #474239;
  border-radius: 8px;
  overflow-wrap: 'text';
  background-color: #282828;
  overflow: hidden;
}

.profile-stat-box {
  padding: 8px 18px 8px 16px;
  margin-right: 8px;
  background-color: #1c1c1c;
  border-radius: 8px;
}

.profile-bg {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;
  -webkit-mask: linear-gradient(180deg, rgba(0, 0, 0, 1) 78%, rgba(0, 0, 0, 0) 100%);
  mask: linear-gradient(180deg, rgba(0, 0, 0, 1) 78%, rgba(0, 0, 0, 0) 100%);
}

.char-splash-rightmask {
  -webkit-mask: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
  mask: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
}

.char-splash-leftmask {
  -webkit-mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0s, transform 0s;
}

.char-splash-leftmask.char {
  opacity: 20%;
  transform: translateX(0);
  transition: opacity 0.75s, transform 0.75s;
}

.char-splash-leftmask.default {
  opacity: 80%;
  transform: translateX(0);
  transition: opacity 0.75s, transform 0.75s;
}

.char-splash-leftmask.none {
  opacity: 0%;
}

.char-splash-topmask {
  -webkit-mask: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%);
  mask: linear-gradient(180deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 80%);
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0s, transform 0s;
}

.char-splash-topmask.char {
  opacity: 20%;
  transform: translateY(0);
  transition: opacity 0.75s, transform 0.75s;
}

.char-splash-topmask.default {
  opacity: 80%;
  transform: translateY(0);
  transition: opacity 0.75s, transform 0.75s;
}

.char-stats-display {
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 4px 8px;
}

.fade-text {
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: hidden;
  position: relative;
  -webkit-mask: linear-gradient(90deg, rgba(0, 0, 0, 1) 89%, rgba(0, 0, 0, 0) 100%);
  mask: linear-gradient(90deg, rgba(0, 0, 0, 1) 89%, rgba(0, 0, 0, 0) 100%);
}

.leaderboard-player {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #00000034;
  transition: background-color 0.2s ease;
  padding: 2px 8px 2px 8px;
}

.leaderboard-player:hover {
  background-color: #ffffff0f;
}

.leaderboard-player--part {
  display: flex;
  align-items: center;
  gap: 8px;
}

.leaderboard-player--part>p {
  margin: 0;
}

.leaderboard-selector {
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  color: white;
  text-shadow: 2px 2px 2px black;
  border: 2px solid #383838;
  transition: border 0.3s ease;
  border-radius: 8px;
  font-family: "Anuphan", sans-serif;
}

.leaderboard-selector>img {
  opacity: 25%;
  transition: opacity 0.3s ease;
}

.leaderboard-selector.selected {
  border: 2px solid #236DAD;
}

.leaderboard-selector.selected>img {
  opacity: 50%;
}

.lb-next-btn {
  background: none;
  border: none;
  color: #848484;
  transition: color 0.2s ease;
  border-radius: 99px;
  background-color: #00000034;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
}

.lb-next-btn:hover {
  color: white;
}

.guide-infographic-panel {
  position: relative;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #FFFFFF11;
  box-shadow: 1px 1px 11px #00000066;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.charguide-container {
  background-image: url('./assets/clearoff.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.image-hover {
  transition: transform 0.1s ease-in-out;
}

.image-hover:hover {
  transform: scale(1.1);
}

.info-table {
  border-collapse: collapse;
}

.info-table td,
.info-table th {
  border: 1px solid white;
  padding: 8px;
}

.info-table tr:first-child th {
  border-top: 0;
}

.info-table tr:last-child td {
  border-bottom: 0;
}

.info-table tr td:first-child,
.info-table tr th:first-child {
  border-left: 0;
}

.info-table tr td:last-child,
.info-table tr th:last-child {
  border-right: 0;
}

.build-card-tab {
  background: none;
  border: none;
  color: white;
  background-color: #00000034;
  cursor: pointer;
  height: 48px;
  width: 138px;
  padding: 8px;
  box-sizing: border-box;
  font-family: "Anuphan", sans-serif;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.build-card-tab:hover {
  border-bottom: 1px solid #848484;
  background-color: #00000050;
}

.build-card-tab.selected {
  border-bottom: 1px solid white;
}

.radar-graph>p {
  margin: 0
}

.radar-container {
  position: relative;
  background: radial-gradient(circle, rgba(243, 174, 125, 0.4) 0%, rgba(225, 243, 125, 0.4) 33%, rgba(94, 181, 99, 0.4) 69%);
  width: 75%;
  height: 75%;
  clip-path: polygon(calc(50% + (sin(0turn) * 50%)) calc(50% - (cos(0turn) * 50%)),
      calc(50% + (sin(0.2turn) * 50%)) calc(50% - (cos(0.2turn) * 50%)),
      calc(50% + (sin(0.4turn) * 50%)) calc(50% - (cos(0.4turn) * 50%)),
      calc(50% + (sin(0.6turn) * 50%)) calc(50% - (cos(0.6turn) * 50%)),
      calc(50% + (sin(0.8turn) * 50%)) calc(50% - (cos(0.8turn) * 50%)));

}

.radar-fill {
  background-color: #648bff;
  width: 100%;
  height: 100%;
  position: absolute;
  clip-path: polygon(calc(50% + (sin(0turn) * 0%)) calc(50% - (cos(0turn) * 0%)),
      calc(50% + (sin(0.2turn) * 0%)) calc(50% - (cos(0.2turn) * 0%)),
      calc(50% + (sin(0.4turn) * 0%)) calc(50% - (cos(0.4turn) * 0%)),
      calc(50% + (sin(0.6turn) * 0%)) calc(50% - (cos(0.6turn) * 0%)),
      calc(50% + (sin(0.8turn) * 0%)) calc(50% - (cos(0.8turn) * 0%)));
  transition: clip-path 0.5s ease;
}

.radar-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radar-label>h1 {
  font-size: 18px;
  margin: 0
}

.radar-label>p {
  width: 60px;
  font-size: 12px;
  margin: 4px;
  text-align: center;
  color: #848484
}

.grid-bg {
  background-size: 19.8px 19.8px;
  background-image:
    linear-gradient(to right, #84848414 1px, transparent 1px),
    linear-gradient(to bottom, #84848414 1px, transparent 1px);
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  background-color: #000000;
  transition: background-color 0.5s ease;
  width: 22px;
  height: 22px;
}

.progress-bar>p {
  text-align: center;
  margin: 0;
  line-height: 12px;
  font-size: 12px;
  color: black;
}

.progress-bar>b {
  font-weight: 1000;
  color: black;
}

.progress-bar-fill {
  transition: width 0.5s ease;
}

.char-guide-panel {
  display: grid;
  height: 400px;
  overflow-y: auto;
  gap: 4px;
  background-color: #0F0F0F;
  padding: 4px;
  flex: 1;
}

.char-guide-item {
  display: flex;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
}

.char-guide-item>img {
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.char-guide-item:hover>img {
  transform: scale(1.1);
}

.header-btn {
  padding: 8px 16px 8px 16px;
  min-width: 80px;
  height: 100%;
  background-color: #0F0F0F;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.header-btn:hover {
  background-color: #1f1f1f;
}

.auth-panel>label {
  margin: 16px 16px 4px 0px;
}

.auth-panel>input {
  height: 32px;
  background: none;
  border: 1px solid #484239;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid #00a2ed44;
  font-family: "Anuphan", sans-serif;
}

.auth-panel>input:focus {
  outline: 3px solid #00a2ed44;
}

.auth-panel>button {
  margin: 16px 16px 16px 0px;
}

.signup-instructions>p {
  margin: 0
}

.hover-link {
  color: white;
  text-decoration: none;
}

.hover-link:hover {
  text-decoration: underline;
}

.change-banner-btn {
  opacity: 50%;
  cursor: pointer;
  position: absolute;
  top: 0;
  margin: 8px;
  color: white;
  background: none;
  border: none;
  font-family: "Anuphan", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center
}

.change-banner-btn:hover {
  opacity: 100%
}

.pfp-upload {
  position: absolute;
  background: #202020;
  border: 1px solid #484239;
  bottom: -72px;
  left: 50%;
  width: 150px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  background-color: #202020;
  padding: 4px 0px 4px 0px;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8))
}

.pfp-upload:after,
.pfp-upload:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.pfp-upload:after {
  border-color: rgba(15, 15, 15, 0);
  border-bottom-color: #202020;
  border-width: 8px;
  margin-left: -8px;
}

.pfp-upload:before {
  border-color: rgba(72, 66, 57, 0);
  border-bottom-color: #484239;
  border-width: 9px;
  margin-left: -9px;
}

.pfp-upload>button,
.pfp-upload>label {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.pfp-upload>button:hover,
.pfp-upload>label:hover {
  background-color: #00000034;
}

.sub-section {
  margin-left: 20px;
  font-size: 15px;
}

.hidden-checkbox {
  display: none;
}

.default-checkbox {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.default-checkbox:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 14px;
  height: 14px;
  border: 1px solid #343434;
  background-color: #0A0A0A;
}

.hidden-checkbox:checked+.default-checkbox:before {
  background-color: #FAC573;
}

.default-checkbox:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.hidden-checkbox:checked+.default-checkbox:after {
  display: block;
}

.charrelic {
  cursor: pointer;
  box-shadow: inset 0px 0px 6px #00000069;
}

.charrelic:hover {
  box-shadow: inset 0px 0px 6px #cccccc69;
}

.relic-calcs {
  border: 1px solid rgb(134, 134, 134);
  background-color: #161616;
  height: 50vh;
  width: 625px;
  overflow: hidden;
  border-radius: 0px 26px 0px 0px;
  box-shadow: -6px 6px rgb(134, 134, 134);
}

.relic-calcs-content {
  display: flex;
  flex-direction: column;
  display: inline-block;
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  width: 625px;
  overflow: auto;
}

.relic-calcs>p,
.relic-calcs-table>p,
.relic-calcs-content>p {
  margin: 0;
}

.relic-calcs-table {
  padding: 8px 8px 0px 8px;
}

.guide-stat-weights {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-auto-flow: column;
}

.dropdown-arrow:after {
  content: '';
  border: 4px solid transparent;
  border-top: 4px solid white;
  margin-left: 8px;
  margin-top: 3px;
}

.dropup-arrow:after {
  content: '';
  border: 4px solid transparent;
  border-bottom: 4px solid white;
  margin-left: 8px;
  margin-bottom: 3px;
}

.clear-button {
  cursor: pointer;
  background: none;
  border: none;
  color: #EFEFEF;
  padding: 0;
  font-family: "Anuphan", sans-serif;
  font-size: 14px;
}
